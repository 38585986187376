import React, { Component } from 'react';
import './HaozIndex.less';
import { Link } from 'react-router-dom'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Banner from './Banner/Banner';
import IndexContent from './IndexContent/IndexContent';
import dayTourImage from './images/Day Tour.png'
import taiwanLogo from './images/taiwan-logo.png'

class HaozIndex extends Component {
    
    
    

    render() {
        return (
            <div style={{"position":"relative"}}>
                <Header />
                <Banner />
                <div className="day-tour-block" style={{"background":"url('"+dayTourImage+"')"}}>
                    <div className="day-tour-center">
                        <div className="day-tour-text">
                            <h1>Day Tour</h1>
                            <p>行程規劃輕鬆拼</p>
                            <Link to="/tour-set">
                                <div className="day-tour-btn">點我成行</div>
                            </Link>
                        </div>
                    </div>
                    <div className="external">
                        <p>對台灣不了解？</p>
                        <a href="https://www.taiwan.net.tw/">
                            <div className="external-btn">
                                <img src={taiwanLogo} alt=""/>
                                <i class="fas fa-angle-double-right"></i>
                            </div>
                        </a>
                    </div>

                </div>
                <IndexContent />
                <Footer />
            </div>
        );
    }
}

export default HaozIndex;