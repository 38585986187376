import React, { Component } from 'react';
import LocalStorage from '../../Helpers/LocalStorage';
import './TotalPrice.less';
import axios from 'axios';
import qs from "query-string";
import q from 'qs';
import moment from 'moment';

class TotalPrice extends Component {
    constructor(props){
        super(props)        
    }

    validateRequired(){
        const{currentState}= this.props;
        let result = true;        
        let requiredItems = document.getElementsByClassName("required")
        let items = Array.from(requiredItems)
        items.map((item)=>item.classList.remove("error"))
        items.map((item)=>{            
            if(currentState[item.name] == "" || currentState[item.name] == 0){
                if(item.name.indexOf("basic")>-1||item.name.indexOf(currentState["service"])>-1){
                    result = false;
                    item.classList.add("error")
                    
                }              
            }
        })
    
        return result;
    }

    validateNumber(){
        const{currentState}= this.props;
        let result = true;
        let requiredItems = document.getElementsByClassName("numberRequired")
        let items = Array.from(requiredItems)
        items.map((item)=>{
            if(isNaN(parseInt(currentState[item.name]))){
                if(item.name.indexOf("basic")>-1||item.name.indexOf(currentState["service"])>-1){
                    result = false;
                    item.classList.add("error")
                }                
            }     
        })
        
        return result;
    }

    submitOrder = (e)=>{
        const{currentState, updateOrderInfo}= this.props;
        this.currentState = currentState;
        let success = false;
        if(this.validateRequired() && this.validateNumber()){
            success = true;
        }else{
            alert("以下資料未填寫完整")
        }

        if(!success)
            return false;


        document.getElementById("loadingToken").style.display = "block";
        let content = ""
        let pickUpContent = "";        
        pickUpContent += "機場: "+ this.currentState["selectedAirport"]+"\n";
        pickUpContent += "航空公司代碼/班機號碼: "+ this.currentState["pickUpAirCodeAndFlightNumber"]+"\n";
        pickUpContent += "班機抵達日期及時間: "+ this.currentState["pickUpAirArriveTime"]+"\n";
        pickUpContent += "總人數(人數包含小孩及大人): "+ this.currentState["pickUpPeopleCount"]+"\n";
        pickUpContent += "嬰兒椅: "+ this.currentState["pickUpBabySeatCount"]+"\n";
        pickUpContent += "兒童椅: "+ this.currentState["pickUpChildSeatCount"]+"\n";
        pickUpContent += "ZIP: "+ this.currentState["zipCode"]+"\n";
        pickUpContent += "城市: "+ this.currentState["pickUpCity"]+"\n";
        pickUpContent += "接送地址: "+ this.currentState["pickUpAddress"]+"\n";
        pickUpContent += "備註: "+ this.currentState["pickUpRemark"]+"\n";
        let pickOffContent = "";
        pickOffContent += "機場: "+ this.currentState["selectedAirport"]+"\n";
        pickOffContent += "航空公司代碼/班機號碼: "+ this.currentState["pickUpAirCodeAndFlightNumber"]+"\n";
        pickOffContent += "班機抵達日期及時間: "+ this.currentState["pickOffAirArriveTime"]+"\n";
        pickOffContent += "總人數(人數包含小孩及大人): "+ this.currentState["pickOffPeopleCount"]+"\n";
        pickOffContent += "嬰兒椅: "+ this.currentState["pickOffBabySeatCount"]+"\n";
        pickOffContent += "兒童椅: "+ this.currentState["pickOffChildSeatCount"]+"\n";
        pickOffContent += "ZIP: "+ this.currentState["zipCode"]+"\n";
        pickOffContent += "接送時間: "+ this.currentState["pickOffTime"]+"\n";
        pickOffContent += "城市: "+ this.currentState["pickOffCity"]+"\n";
        pickOffContent += "接送地址: "+ this.currentState["pickOffAddress"]+"\n";
        pickOffContent += "備註: "+ this.currentState["pickOffRemark"]+"\n";
        if(this.currentState["service"] == "pickUp")
            content = pickUpContent;
        else
            content = pickOffContent;
        
        let selectedAirport = document.getElementById("selectedAirport");
        let selectedAirportIndex = selectedAirport.selectedIndex;
        let travelerList;
        if(this.currentState["pickOffTime"]){//送機
            travelerList = [
                {
                    "lastTName": this.currentState["basicEnLastName"],
                    "firstTName": this.currentState["basicEnFirstName"],
                    "lastEName": this.currentState["basicEnLastName"],
                    "firstEName": this.currentState["basicEnFirstName"],
                    "adultOrChild": "ADULT",
                    "singleRentCarAgendum":{
                        "quantity":this.currentState["pickOffPeopleCount"],
                        "price":this.props.totalPrice,
                        "getOffZipPrefixCode":this.currentState["zipCode"],
                        "boardLocation":this.currentState["pickOffAddress"],
                        "getOffLocation":selectedAirport.options[selectedAirportIndex].text,
                        "rentCarStartDate":moment(this.currentState["pickOffTime"]).format('YYYY-MM-DDTHH:mm:ss'),
                        "boardTime":this.currentState["pickOffTime"],
                        "type":"DROP_OFF",
                        "babyChairQuantity": this.currentState["pickOffBabySeatCount"],
                        "babyChairPrice": 0,
                        "childrenChairQuantity": this.currentState["pickOffChildSeatCount"],
                        "childrenChairPrice": 0,
                        "flightCode": this.currentState["pickOffAirCodeAndFlightNumber"],
                        "flightTime": moment(this.currentState["pickOffAirArriveTime"]).format('YYYY-MM-DDTHH:mm:ss'),
                        "remark": this.currentState["pickOffRemark"]                            
                    }
                }
            ]
        }else{
            travelerList = [
                {
                    "lastTName": this.currentState["basicEnLastName"],
                    "firstTName": this.currentState["basicEnFirstName"],
                    "lastEName": this.currentState["basicEnLastName"],
                    "firstEName": this.currentState["basicEnFirstName"],
                    "adultOrChild": "ADULT",
                    "singleRentCarAgendum":{
                        "quantity":this.currentState["pickOffPeopleCount"],
                        "price":this.props.totalPrice,
                        "boardZipPrefixCode":this.currentState["zipCode"],
                        "boardLocation":selectedAirport.options[selectedAirportIndex].text,
                        "getOffLocation":this.currentState["pickUpAddress"],
                        "rentCarStartDate":moment(this.currentState["pickUpAirArriveTime"]).format('YYYY-MM-DDTHH:mm:ss'),
                        "boardTime":this.currentState["pickUpAirArriveTime"],
                        "type":"PICK_UP",
                        "babyChairQuantity": this.currentState["pickUpBabySeatCount"],
                        "babyChairPrice": 0,
                        "childrenChairQuantity": this.currentState["pickUpChildSeatCount"],
                        "childrenChairPrice": 0,
                        "flightCode": this.currentState["pickUpAirCodeAndFlightNumber"],
                        "flightTime": moment(this.currentState["pickUpAirArriveTime"]).format('YYYY-MM-DDTHH:mm:ss'),
                        "remark": this.currentState["pickUpRemark"]                        
                    }
                }
            ]
        }
        let basicSocialType = this.currentState["basicSocialType"];
        let basicSocialNumber_line = "";
        let basicSocialNumber_wechat = "";
        if(basicSocialType=='line'){
            basicSocialNumber_line = this.currentState["basicSocialNumber"];
        }else if(basicSocialType=='wechat'){
            basicSocialNumber_wechat = this.currentState["basicSocialNumber"];
        }
        const requestBody = {
            "customerId":null,
            "customer":{
                "lastTName": this.currentState["basicEnLastName"],
                "firstTName": this.currentState["basicEnFirstName"],
                "lastEName": this.currentState["basicEnLastName"],
                "firstEName": this.currentState["basicEnFirstName"],
                "adultOrChild": "ADULT", 
                "email": this.currentState["basicEmail"],
                "mobile": this.currentState["basicPhoneCode"]+" "+ this.currentState["basicPhoneNumber"],  
                "nationality": this.currentState["basicNation"],
                "line": basicSocialNumber_line,
                "weChat": basicSocialNumber_wechat                   
            },
            "orderNumber":null,
            "orderRemark":null,
            "orderFromType":null,
            "salesId":null,
            "taskType":"RENT_CAR",
            "travelerList": travelerList
        } 
        
        
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        }
        let apiUrl = "https://api.marisantravel.clicugo.com"; 

        axios.post(apiUrl+'/C/business/webservice/orderService-saveSimpleSalesOrderAndCustomer'
            ,requestBody
            ,config).then(saveOrder => {
                
                axios.get(apiUrl+"/C/business/webservice/orderService-getFixedSalesOrderBySalesOrderNumber?salesOrderNumber="+saveOrder.data.salesOrderNumber).then(getFixedSalesOrder=>{
                    updateOrderInfo(getFixedSalesOrder.data.salesOrderNumber, getFixedSalesOrder.data.paymentLink);
                })            
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    JSON_to_URLEncoded(element,key,list){
      var list = list || [];
      if(typeof(element)=='object'){
        for (var idx in element){
            if(Array.isArray(element))
                this.JSON_to_URLEncoded(element[idx],key?key+'['+idx+']':idx,list);
            else
                this.JSON_to_URLEncoded(element[idx],key?key+'.'+idx+'':idx,list);
        }
      } else {
        list.push(key+'='+encodeURIComponent(element));
      }
      return list.join('&');
    }

    render() {
        const {showForm, totalPrice, firstPrice } = this.props;      
        return (
            <div className={(showForm?"":"hide")}>
                <div id="total-price">               
                    <div id="total-price-content">
                        <div className="price-block">第1人起算價格為 {firstPrice}元, 多增加1人收 5元</div>
                        <div className="price-block">                                                 
                            <p className="text"><i className="fas fa-shopping-cart"></i>&nbsp;總金額</p> <p className="price">NT${totalPrice}</p><p className="text">元 (小費另計)</p>
                        </div>
                    </div>                    
                </div>
                <div className="bill-block">
                    <div className="bill-inner">
                        <div className="form-row">                    
                            <div className="row-btn center" name="submitBtn" style={{"padding": "10px 0", "display":"flex","justifyContent":"center", "alignItems":"center"}} onClick={this.submitOrder.bind(this)}>
                                <div>訂購</div>
                                <div id="loadingToken" style={{"marginLeft":"20px", "display":"none"}} className="lds-ripple"><div></div><div></div></div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TotalPrice;