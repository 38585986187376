import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './SwitchStep.less';
import LocalStorage from '../Helpers/LocalStorage';

class SwitchStep extends Component {
    constructor(props) {
        super(props);
        this.nextStep2Button = false
        this.nextStep3Button = false
        this.nextStep4Button = false
    }
    componentWillMount() {
        const $ = window.$
        $(function() { 
            $(window).scroll(function() {
                var footerHeight = $(".footer").outerHeight();
                var scrollBottom = $(document).height() - $(window).height() - $(window).scrollTop(); 
                if (scrollBottom >= footerHeight) {
                    $("#result").css({"bottom":"0","position":"fixed","z-index": "99","left":"0","width": "100%"}); 
                }else{
                    $("#result").css({"position":"static","width": "100%"}); 
                }
            }); 
        });
    }
    componentDidMount() {
        const $ = window.$
        $(function() { 
            $(window).scroll(function() {
                var footerHeight = $(".footer").outerHeight();
                var scrollBottom = $(document).height() - $(window).height() - $(window).scrollTop(); 
                if (scrollBottom >= footerHeight) {
                    $("#result").css({"bottom":"0","position":"fixed","z-index": "99","left":"0","width": "100%"}); 
                }else{
                    $("#result").css({"position":"static","width": "100%"}); 
                }
            }); 
        });

    }
    showAlert(){
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if(haoztravelLS == null){
            haoztravelLS = {};
        }
        this.pickedDate = haoztravelLS.pickedDate
        if(this.props.nextPage === "/tour-set/step2"){
            if(this.pickedDate === undefined){
                alert("請選擇日期!")
            }else if(this.props.adultNum === 0){
                alert("至少選擇一位大人!")
            }
        }
        if(this.props.nextPage==="/tour-set/step3"){
            if(this.clickedTourList === undefined||this.clickedTourList.length === 0){
                alert("您還尚未選取行程！")
            }
        }
        if(this.props.nextPage==="/tour-set/step4"){
            if(this.nextStep4Button === false){
                alert("你還尚未安排行程！")
                return false
            }
        }
    }
    calPriceDetail() {
        let isValid = this.showAlert();
        if( isValid !== false){
            let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
            if(haoztravelLS == null){
                haoztravelLS = {};
            }
            let finalTotalAdultPrice = 0
            let finalTotalKidPrice = 0
            let finalTotalKidNBPrice = 0
            this.customizedList = haoztravelLS.customizedList
            this.customizedList.forEach(item => {
                if(item.content[0][0] !== undefined){
                    finalTotalAdultPrice += item.content[0][0].totalAdultPrice
                    finalTotalKidPrice += item.content[0][0].totalKidPrice
                    finalTotalKidNBPrice += item.content[0][0].totalKidNBPrice
                }
            })
            let priceDetail = [finalTotalAdultPrice,finalTotalKidPrice,finalTotalKidNBPrice]
            
            haoztravelLS["priceDetail"] = priceDetail;
            LocalStorage.saveLocalStorage("haoztravel",haoztravelLS);
        }
        
    }
    render() {
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if(haoztravelLS == null){
            haoztravelLS = {};
        }
        
        this.pickedDate = haoztravelLS.pickedDate
        this.clickedTourList = haoztravelLS.clickedTourList
        this.EachDayToursID = haoztravelLS.EachDayToursID
        
        
        if(this.props.nextPage==="/tour-set/step2"){
            if(this.props.adultNum === 0||this.pickedDate === undefined){
                this.nextStep2Button = false
            }else{
                this.nextStep2Button = true
            }
            this.nextButton = this.nextStep2Button
        }else if(this.props.nextPage==="/tour-set/step3"){
            if(this.clickedTourList === undefined||this.clickedTourList.length === 0){
                this.nextStep3Button = false
            }else{
                this.nextStep3Button = true
            }
            this.nextButton = this.nextStep3Button
        }else if(this.props.nextPage === "/tour-set/step4"){
            this.nextStep4Button = false
            Object.keys(this.EachDayToursID).forEach(item => {
                if(this.EachDayToursID[item][0].length>0||this.EachDayToursID[item][1].length>0){
                    this.nextStep4Button = true
                }
            })
            this.nextButton = this.nextStep4Button
        }
        return (
            <div id="result">            
                <Link to={{pathname:this.props.prePage, search:this.props.queryString}}>
                    <div id="pre-button" style={this.props.prePage === undefined?{"display":"none"}:{}}>
                        <i className="fas fa-angle-double-left"></i>上一步
                    </div>
                </Link>
                { this.props.prePage === "/tour-set/step3"?
                    <Link to={{pathname:this.props.nextPage, search:this.props.queryString}}>
                        <div id="submit-button" className="unshow">{this.props.nextPage === "/tour-set/step1" ? "回到首頁" : "下一步" }</div>
                    </Link>:
                    this.props.prePage === "/tour-set/step2"?
                    <Link to={this.nextButton===true?{pathname:this.props.nextPage, search:this.props.queryString}:{pathname:"", search:this.props.queryString}} onClick={this.calPriceDetail.bind(this)}>
                        <div id="submit-button">{this.props.nextPage === "/tour-set/step1" ? "回到首頁" : "下一步" }</div>
                    </Link>:
                    <Link to={this.nextButton===true?{pathname:this.props.nextPage, search:this.props.queryString}:{pathname:"", search:this.props.queryString}} onClick={this.showAlert.bind(this)}>
                        <div id="submit-button">{this.props.nextPage === "/tour-set/step1" ? "回到首頁" : "下一步" }</div>
                    </Link>
                }
            </div>
        );
    }
}



export default SwitchStep;