import React, { Component } from 'react';
import LocalStorage from '../../Helpers/LocalStorage';
import './PickedTourList.less'
class PickedTourList extends Component {
    constructor(props){
        super(props);
        const { deleteTour,selectTourDate } = this.props;
        this.deleteTour = deleteTour;
        this.selectTourDate = selectTourDate;
    }

    

    render() {
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if (haoztravelLS == null) {
            haoztravelLS = {};
        }
        this.copyClickedTourList = haoztravelLS.copyClickedTourList;
        this.clickedTourList = haoztravelLS.clickedTourList;
        this.toursSelectDate = haoztravelLS.toursSelectDate;
        let test = this.copyClickedTourList===undefined?this.clickedTourList:this.copyClickedTourList
        return (

            <div id="picked-tour-list">
                {
                    test.map((tourListItem,key) =>
                        <div className="picked-tour" key={key}>
                            <div className="picked-tour-l">
                                <div className="picked-tour-img" style={{"background":"url('"+tourListItem.photo+"')"}}></div>
                                <div className="picked-tour-title">{tourListItem.tourSubject}</div>

                            </div>
                            <div className="picked-tour-r">

                                <select name="tour-pick-day" className="tour-pick-day" onChange={(e)=>{this.selectTourDate(e, tourListItem.packageId, tourListItem.tourDay)}}  value={this.toursSelectDate && this.toursSelectDate[tourListItem.packageId]||'選擇日期'}>
                                    {tourListItem.dateOption.map((item,key) =>
                                        <option value={item} key={key}>{item}</option>
                                    )}
                                </select>
                                <div className="picked-tour-price">＄{tourListItem.validPrice===undefined?tourListItem.minPrice:tourListItem.validPrice}</div>
                                <div className="picked-tour-r-btn picked-tour-days"><p>{tourListItem.tourDay}</p><p>day</p></div>
                                <div className="picked-tour-r-btn picked-tour-delete-btn" onClick={this.deleteTour.bind(this,tourListItem.packageId,tourListItem.tourDay)}><i className="fas fa-minus"></i></div>
                            </div>

                        </div>
                    )
                }
            </div>
        );
    }
}

export default PickedTourList;