import React, { Component } from 'react';
import UndecidedPickedTourList from './UndecidedPickedTourList/UndecidedPickedTourList';
import GuestInfo from '../GuestInfo/GuestInfo';
import LocalStorage from '../Helpers/LocalStorage';
import TourList from './TourList/TourList';
import axios from 'axios';
import searchProduct from '../searchProduct.json';
import qs from 'qs';

class Step2Content extends Component {
    constructor(props) {
        super(props);

        const { tourList, changeTourClickState, deleteTour, calPackageMinPrice, initialStepObject, setCart,calSumPeople,calPackagePrice } = this.props;
        this.state = {
            isLodgingSuccess : false,
            test: false,
            test1: false,
            test2: false,
            testValue: "",
            exception: "我還沒抓到值"
        }
        this.tourList = tourList;
        this.calPackagePrice = calPackagePrice;
        this.calSumPeople = calSumPeople
        this.setCart = setCart;
        this.changeTourClickState = changeTourClickState;
        this.deleteTour = deleteTour;
        this.initialStepObject = initialStepObject;
        this.searchProduct = "";
        this.calPackageMinPrice = calPackageMinPrice;
        this.calSumPeople = this.calSumPeople.bind(this);
        this.calPackagePrice = this.calPackagePrice.bind(this);
        this.getReformetTourData = this.getReformetTourData.bind(this);
        this.tmpGetReformetTourData = this.tmpGetReformetTourData.bind(this);
        this.compare = this.compare.bind(this);
    }

    componentWillMount() {

        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if(haoztravelLS == null){
            haoztravelLS = {};
        }
        this.tourListData = haoztravelLS.tourListData
        // this.tmpGetReformetTourData()
        this.getReformetTourData()

    }
    componentDidMount() {
    }
    getReformetTourData() {

        this.setState({test:true})
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if(haoztravelLS == null){
            haoztravelLS = {};
        }

        

        this.tourListData = haoztravelLS.tourListData
        this.pickUP = haoztravelLS.pickUP
        this.dropOFF = haoztravelLS.dropOFF
        this.pickedDateInterval = haoztravelLS.pickedDateInterval 
        if(this.tourListData===undefined||this.tourListData===""){
            
            let pickedDateStart = haoztravelLS.pickedDateStart.substr(0,10)
            let pickedDateEnd = haoztravelLS.pickedDateEnd.substr(0,10)

            try {
                // axios.get( 'http://dev.leyo.tw/C/tour/webservice/turPackageService-queryGroupTurPackagesForAgent', qs.stringify({'criteria.departureDateStart': pickedDateStart, 'criteria.departureDateEnd': pickedDateEnd}), {
                //     headers: {
                //        'Content-Type': 'application/x-www-form-urlencoded'
                //     }
                // })
                axios.get('http://dev.leyo.tw/C/tour/webservice/turPackageService-queryGroupTurPackagesForAgent?criteria.departureDateStart='+pickedDateStart+"&"+"criteria.departureDateEnd="+pickedDateEnd,
                { 
                })
                .then(res => {
                    console.log(res)
                    this.searchProduct = res.data;
                    
                    let tourListData = [];
                    this.searchProduct.forEach(function(plan) {
                        plan.packages.forEach(function(singleTour) {
                            console.log(singleTour)
                            let clearDescription = singleTour.description.replace(/<\/?[^>]+(>|$)/g, "");
                            clearDescription = clearDescription.replace(/&nbsp;/g, "");                
                            const singleTourData = {
                                tourSubject: singleTour.tourSubject,
                                description: clearDescription,
                                tourDay: singleTour.tourDay,
                                DateAndPrice: [{
                                    date: singleTour.departureDate,
                                    prices: singleTour.prices
                                }],
                                photo: plan.images[0],
                                packageId: singleTour.packageId,
                                isClick: false,
                                dateOption: [],
                                tourType: plan.tourType
                            }
                            let exist = tourListData.some(item => item.tourSubject === singleTourData.tourSubject)

                            if (exist === false) {

                                plan.packages.forEach(function(element) {
                                    let isNotMatch = true;
                                    if (element.tourSubject === singleTourData.tourSubject) {
                                        singleTourData.DateAndPrice.forEach(function(dateItem) {
                                            if (dateItem.date === element.departureDate) {
                                                isNotMatch = false;
                                            }
                                        })

                                        if (isNotMatch) {
                                            singleTourData.DateAndPrice.push({
                                                date: element.departureDate,
                                                prices: element.prices
                                            })
                                        }
                                    }
                                })
                                singleTourData.DateAndPrice.forEach(function(DateAndPrice) {
                                    singleTourData.dateOption.push(DateAndPrice.date)
                                })
                                singleTourData.dateOption.sort();
                                tourListData.push(singleTourData)
                            }
                        })
                    })
                    tourListData.sort(this.compare('tourDay', 'packageId'))
                    // tourListData.forEach(item => {
                    //     item.tourDay
                    // })
                    let deleteAirTourListData = [];
                    let pickUPTourListData = [];
                    let dropOFFTourListData = [];
                    tourListData.forEach((element) => {
                        let isNeedPush = true;
                        if(haoztravelLS.pickUP===false||haoztravelLS.pickUP===undefined){
                            if(element.tourType==="PICK_UP"){
                                isNeedPush = false
                            }
                        }
                        if(haoztravelLS.dropOFF===false||haoztravelLS.dropOFF===undefined){
                            if(element.tourType==="DROP_OFF"){
                                isNeedPush = false
                            }
                        }
                        if(isNeedPush===true){
                            if(element.tourType==="PICK_UP"){
                                pickUPTourListData.push(element)
                            }else if(element.tourType==="DROP_OFF"){
                                dropOFFTourListData.push(element)
                            }else{
                                deleteAirTourListData.push(element)
                            }
                        }
                    })
                    let clearAirTourListData = pickUPTourListData.concat(dropOFFTourListData).concat(deleteAirTourListData)

                    let clearDateOptionTourListData = []
                    clearAirTourListData.forEach(function(element) {

                        let days = -(element.tourDay - 1)
                        if (days !== 0) {
                            haoztravelLS.pickedDateInterval.slice(days).forEach(function(day) {
                                if (element.dateOption.indexOf(day) !== -1) {
                                    element.dateOption.splice(element.dateOption.indexOf(day))
                                }
                            })
                        }
                        let pickedDateInterval = haoztravelLS.pickedDateInterval
                        let firstDate = pickedDateInterval[0]
                        let LastDate = pickedDateInterval[pickedDateInterval.length-1]

                        if(element.tourType!=="PICK_UP"&element.tourType!=="DROP_OFF"){
                            if(haoztravelLS.pickUP===true){
                                if(element.dateOption.indexOf(firstDate)!==-1){
                                    element.dateOption.splice(element.dateOption.indexOf(firstDate),1)
                                }
                            }
                            if(haoztravelLS.dropOFF===true){
                                for(let i=1;i<=element.tourDay;i++){
                                    if(element.dateOption.indexOf(pickedDateInterval[pickedDateInterval.length-i])!==-1){
                                        element.dateOption.splice(element.dateOption.indexOf(pickedDateInterval[pickedDateInterval.length-i]),1)
                                    }
                                }
                                
                            }
                        }else if(element.tourType === "PICK_UP"){
                            if(element.dateOption.indexOf(firstDate)!==-1){
                                element.dateOption.splice(1)
                            } 
                        }else if(element.tourType === "DROP_OFF"){
                            if(element.dateOption.indexOf(LastDate)!==-1){
                                element.dateOption.splice(0,element.dateOption.length-1)
                            }
                        }


                        if(element.dateOption.length !== 0){
                            clearDateOptionTourListData.push(element)
                        }

                    })
                    clearDateOptionTourListData.forEach(element => {
                        element.dateOption.splice(0,0,"選擇日期")
                    })
                    haoztravelLS["tourListData"] = clearDateOptionTourListData;
                    LocalStorage.saveLocalStorage("haoztravel",haoztravelLS);
                    
                    this.calSumPeople();
                    this.calPackagePrice();
                    this.setState({isLodgingSuccess : true})
                    console.log(LocalStorage.getLocalStorage("haoztravel"))
                })
                .catch(function (error) {

                    console.log(error);
                });
            }catch(exception) {
                alert(exception)
                console.log(exception)
            }
            
        }else{
            this.calSumPeople();
            this.calPackagePrice();
            this.setCart()
            this.setState({isLodgingSuccess : true})
        }
    }
    tmpGetReformetTourData() {
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if(haoztravelLS == null){
            haoztravelLS = {};
        }

        this.tourListData = haoztravelLS.tourListData
        if(this.tourListData===undefined){
            let tourListData = [];
            searchProduct.forEach(function(plan) {
                plan.packages.forEach(function(singleTour) {
                    let clearDescription = singleTour.description.replace(/<\/?[^>]+(>|$)/g, "");
                    clearDescription = clearDescription.replace(/&nbsp;/g, "");                
                    const singleTourData = {
                        tourSubject: singleTour.tourSubject,
                        description: clearDescription,
                        tourDay: singleTour.tourDay,
                        DateAndPrice: [{
                            date: singleTour.departureDate,
                            prices: singleTour.prices
                        }],
                        photo: plan.images[0],
                        packageId: singleTour.packageId,
                        isClick: false,
                        dateOption: [],
                        tourType: plan.tourType
                    }
                    let exist = tourListData.some(item => item.tourSubject === singleTourData.tourSubject)

                    if (exist === false) {

                        plan.packages.forEach(function(element) {
                            let isNotMatch = true;
                            if (element.tourSubject === singleTourData.tourSubject) {
                                singleTourData.DateAndPrice.forEach(function(dateItem) {
                                    if (dateItem.date === element.departureDate) {
                                        isNotMatch = false;
                                    }
                                })

                                if (isNotMatch) {
                                    singleTourData.DateAndPrice.push({
                                        date: element.departureDate,
                                        prices: element.prices
                                    })
                                }
                            }
                        })
                        singleTourData.DateAndPrice.forEach(function(DateAndPrice) {
                            singleTourData.dateOption.push(DateAndPrice.date)
                        })
                        singleTourData.dateOption.sort();
                        tourListData.push(singleTourData)
                    }

                })
            })
            
            let clearAirTourListData = [];
            tourListData.forEach((element) => {
                let isAirExist = false;
                if(haoztravelLS.pickUP===false||haoztravelLS.pickUP===undefined){
                    if(element.tourType==="PICK_UP"){
                        isAirExist = true
                    }
                }
                if(haoztravelLS.dropOFF===false||haoztravelLS.dropOFF===undefined){
                    if(element.tourType==="DROP_OFF"){
                        isAirExist = true
                    }
                }
                if(isAirExist===false){
                    if(element.tourType==="PICK_UP"){
                        clearAirTourListData.splice(0,0,element)
                    }else{
                    }
                }
            })


            let clearDateOptionTourListData = []
            clearAirTourListData.forEach(function(element) {

                let days = -(element.tourDay - 1)
                if (days !== 0) {
                    haoztravelLS.pickedDateInterval.slice(days).forEach(function(day) {
                        if (element.dateOption.indexOf(day) !== -1) {
                            element.dateOption.splice(element.dateOption.indexOf(day))
                        }
                    })
                }
                let pickedDateInterval = haoztravelLS.pickedDateInterval
                let firstDate = pickedDateInterval[0]
                let LastDate = pickedDateInterval[pickedDateInterval.length-1]

                if(element.tourType!=="PICK_UP"&element.tourType!=="DROP_OFF"){
                    if(haoztravelLS.pickUP===true){
                        if(element.dateOption.indexOf(firstDate)!==-1){
                            element.dateOption.splice(element.dateOption.indexOf(firstDate),1)
                        }
                    }
                    if(haoztravelLS.dropOFF===true){
                        for(let i=1;i<=element.tourDay;i++){
                            if(element.dateOption.indexOf(pickedDateInterval[pickedDateInterval.length-i])!==-1){
                                element.dateOption.splice(element.dateOption.indexOf(pickedDateInterval[pickedDateInterval.length-i]),1)
                            }
                        }
                        
                    }
                }else if(element.tourType === "PICK_UP"){
                    if(element.dateOption.indexOf(firstDate) !== -1){
                        element.dateOption.splice(1)
                    } 
                }else if(element.tourType === "DROP_OFF"){
                    if(element.dateOption.indexOf(LastDate) !== -1){
                        element.dateOption.splice(0,element.dateOption.length-1)
                    }
                }


                if(element.dateOption.length!==0){
                    clearDateOptionTourListData.push(element)
                }

            })
            clearDateOptionTourListData.forEach(element => {
                element.dateOption.splice(0,0,"選擇日期")
            })
            haoztravelLS["tourListData"] = clearDateOptionTourListData;
            LocalStorage.saveLocalStorage("haoztravel",haoztravelLS);
            
            this.calSumPeople();
            this.calPackagePrice();
            this.setState({isLodgingSuccess : true})
        }else{
            this.calSumPeople();
            this.calPackagePrice();
            this.setCart()
            this.setState({isLodgingSuccess : true})
        }
    }
    compare(property, property2){
        return function(a,b){
            var aValue1 = a[property];
            var bValue1 = b[property];
            var aValue2 = a[property2];
            var bValue2 = b[property2];
            if(aValue1!==bValue1){
              return aValue1 - bValue1;
            }
            else{
              return aValue2 - bValue2;
            }
        }
    };


    render() {
        return (
            
            <div>
                {this.state.isLodgingSuccess?<div>
                    <GuestInfo 
                    peopleNumberGroup={this.peopleNumberGroup} 
                    pickedDate={this.props.pickedDate}/>
                    <UndecidedPickedTourList deleteTour={this.deleteTour}/>
                    <div id="only-phone">
                        {/* {this.state.testValue}
                        {this.state.test===true?"test有進來":"test沒進來"}
                        {this.state.test1===true?"test有進來1":"test沒進來1"}
                        {this.state.test2===true?"test有進來2":"test沒進來2"} */}
                    </div>
                    <TourList changeTourClickState={this.changeTourClickState} isLodgingSuccess={this.state.isLodgingSuccess}/>
                </div>:<div style={{"textAlign":"center","padding":"50px"}}>資料獲取中 <i className="fas fa-fw fa-spinner fa-spin"></i></div>
                }
                
            </div>
        );
    }
}

export default Step2Content;