import React, { Component } from 'react';
import DatePick from './DatePick/DatePick';
import PeopleNumPick from './PeopleNumPick/PeopleNumPick';

class Step1Content extends Component {
  constructor(props) {
    super(props);
    const { getPickedDate,getAirOption } = this.props;
    this.getPickedDate = getPickedDate
    this.getAirOption = getAirOption
    this.state = {
      persons:[]
    }
  }


  render() {
    const {updatePeopleNumberGroup} = this.props;
    return (    
        <div id="step1content">
            <DatePick 
            maxDate={this.props.maxDate}
            minDate={this.props.minDate} 
            pickedDate={this.props.pickedDate} 
            getPickedDate={this.getPickedDate}
            getAirOption={this.getAirOption}
            pickUP={this.props.pickUP}
            dropOFF={this.props.dropOFF}/>
            <PeopleNumPick updatePeopleNumberGroup={updatePeopleNumberGroup}/>
            {/*<AirPick />
            <ul>
              {this.state.persons.map(person => <li>{person.email}</li>)}
            </ul>*/}
        </div>
    );
  }
}

export default Step1Content;