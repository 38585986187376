import React, { Component } from 'react';
import './Main.less';
import StepDisplay from '../StepDisplay/StepDisplay';
import SmallStepDisplay from '../StepDisplay/SmallStepDisplay';
import SwitchStep from '../SwitchStep/SwitchStep';
import Step1Content from '../Step1Content/Step1Content';
import Step2Content from '../Step2Content/Step2Content';
import Step3Content from '../Step3Content/Step3Content';
import Step4Content from '../Step4Content/Step4Content';
import Step5Content from '../Step5Content/Step5Content';
import LocalStorage from '../Helpers/LocalStorage';
import qs from "query-string";
import Header from '../Haoz/Header/Header';
import Footer from '../Haoz/Footer/Footer';


class Main extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            "orderNumber":"R000000",
            "paymentUrl":"not found",
            "showOrderInput":true,
            "showOrderResult":false,
        }      
        this.updateOrderInfo = this.updateOrderInfo.bind(this)
    }

    updateOrderInfo(orderNumber, paymentUrl){
        this.setState({
            "orderNumber":orderNumber,
            // "paymentUrl":paymentUrl,
            "showOrderInput": false,
            "showOrderResult": true
        });
    }
    backToHome=()=>{
        this.setState({
            "showOrderInput":true,
            "showOrderResult":false,
        })
    }
    
    render() {
        return (
            <div>
                <Header />
                <div id="content">        
                    
                    <div id="content-major">
                        {this.state.showOrderInput?
                            <Step4Content 
                                updateOrderInfo = {this.updateOrderInfo}
                            />:""
                        }
                        {this.state.showOrderResult?
                            <Step5Content 
                                orderNumber = {this.state.orderNumber}
                                backToHome = {this.backToHome}
                            />:""
                        }                                                
                    </div>
                    
                </div>
                <Footer />
            </div>
        );
    }
}

export default Main;