import React, { Component } from 'react';
import '.././Form.less'

class BasicInfoForm extends Component {
    constructor(props) {
        super(props);
    }

    handleNationalityChange = () => {
        let basicNation = this.props.state.basicNation
        let nationContent;
        if (basicNation === "other") {
            nationContent = (
                <div className="row-item" style={{ "display":"flex" }}>
                    <nobr>國籍</nobr>
                    <div id="basicNation" name="basicNation" style={{"display":"flex" }} >
                    <select  name="basicNation"  style={{ "flex": 1, "marginRight": "10px"  }} onChange={this.props.handleFormChange.bind(this)}>
                        <option value=""></option>
                        <option value="taiwan">台灣</option>
                        <option value="china">中國</option>
                        <option value="other" selected>其他</option>
                    </select>
                    <input type="text" placeholder="請填寫國籍" className="nationality" name="nationality" style={{ "flex": 2}} onKeyUp={this.props.handleFormChange.bind(this)} />
                </div>
                </div>
            )
        } else {
            nationContent = (
                <div className="row-item" style={{ "display":"flex" }}>
                    <nobr>國籍</nobr>
                    <div id="basicNation" name="basicNation" style={{"display":"flex" }} >
                    <select id="basicNation" name="basicNation"  style={{ "width": "100%", "marginRight": "10px" }} onChange={this.props.handleFormChange.bind(this)}>
                        <option value="">請選擇</option>
                        <option value="TW">台灣</option>
                        <option value="CN">中國</option>
                        <option value="OT">其他</option>
                    </select>
                </div>
                     </div>)
        }
        return nationContent
    }



    

    render() {
        const { showForm } = this.props;
        return (
            <div className={"bill-block " + (showForm ? "" : "hide")}>
                <div className="bill-inner-title">訂購人填寫基本資料</div>
                <div className="bill-inner">
                    <div className="form-row">
                        <div className="row-item"><nobr className="titleRequired">英文姓</nobr> <input type="text" name="basicEnLastName" className="required" onKeyUp={this.props.handleFormChange.bind(this)} /></div>
                        <div className="row-item"><nobr className="titleRequired">英文名</nobr> <input type="text" name="basicEnFirstName" className="required" onKeyUp={this.props.handleFormChange.bind(this)} /></div>
                    </div>
                    <div className="form-row">
                        <div className="row-item"><nobr className="titleRequired">郵件信箱</nobr> <input type="text" name="basicEmail" className="required" onKeyUp={this.props.handleFormChange.bind(this)} /></div>
                        <div className="row-item">
                            <nobr>LINE/WeChat</nobr>
                            <div id="communication">
                                <select name="basicSocialType" onChange={this.props.handleFormChange.bind(this)}>
                                    <option value="">請選擇</option>
                                    <option value="line">LINE</option>
                                    <option value="wechat">WeChat</option>
                                </select>
                                <input type="text" placeholder="請填寫帳號" name="basicSocialNumber" onChange={this.props.handleFormChange.bind(this)} />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        {this.handleNationalityChange()}
                        <div className="row-item">
                            <nobr className="titleRequired">手機電話</nobr>
                            <div className="phone">
                                <input type="text" name="basicPhoneCode" placeholder="國碼" className="required" style={{ "width": "60px", "marginRight": "10px" }} onKeyUp={this.props.handleFormChange.bind(this)} />
                                <input type="text" name="basicPhoneNumber" className="required" onKeyUp={this.props.handleFormChange.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BasicInfoForm;