import React, { Component } from 'react';
import LocalStorage from '../../Helpers/LocalStorage';
import './CustomizedList.less'
class CustomizedList extends Component {
    
    componentWillMount() {
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        this.pickedDateStart = haoztravelLS.pickedDateStart;
    }
    render() {
        
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if (haoztravelLS == null) {
            haoztravelLS = {};
        }
        this.totalPrice = haoztravelLS.totalPrice;
        this.customizedList = haoztravelLS.customizedList;
        this.pickUP = haoztravelLS.pickUP;
        this.dropOFF = haoztravelLS.dropOFF;
        let isPickUPArrange = false
        let isdropOFFArrange = false
        if(this.pickUP === true & this.customizedList[0].content[0].length === 0 & this.customizedList[0].content[1].length === 0){
            isPickUPArrange = false
        }else{
            isPickUPArrange = true
        }
        if(this.dropOFF === true & this.customizedList[this.customizedList.length-1].content[0].length === 0 & this.customizedList[this.customizedList.length-1].content[1].length === 0){
            isdropOFFArrange = false
        }else{
            isdropOFFArrange = true
        }
        
        let notArrangeDaysNum = 0
        this.customizedList.forEach(item =>
            {
                if(item.content[0].length === 0 & item.content[1].length === 0){
                    notArrangeDaysNum++
                }
            }
        )

        return (
            <div>
                <div className="panel-group" id="accordion">
                    <div className="panel panel-default">
                        <div className="panel-heading" data-toggle="collapse" data-parent="#accordion" 
                                href="#collapse">
                                <div id="accordion-title-text">
                                    <div id="title-name">旅遊行程計畫表</div>  
                                    <div id="title-tip">
                                        <div id="tip-text">尚有 {notArrangeDaysNum} 天未安排</div>
                                        <div id="price-block"><i className="fas fa-shopping-cart"></i><div id="price">$ {this.totalPrice}</div></div>
                                    </div>
                                </div>
                                <div id="accordion-title-sign">
                                    <i className="fa fa-angle-down menu__icon--open"></i>
                                    <i className="fa fa-angle-up menu__icon--close"></i>
                                </div>
                        </div>
                        <div id="collapse" className="panel-collapse collapse" aria-expanded="false">
                                <div id="customized-list">            
                                    <div id="customized-list-inner">
                                    {this.customizedList.map((dayItem,index) => {
                                        let simple = dayItem.date.substr(5)
                                        return(
                                            <div className="customized-list-item-outer" key={'c_'+index}>
                                                <div className={dayItem.content[0].length===0&dayItem.content[1].length===0?'c-l-i-empty customized-list-item':'customized-list-item'}>
                                                    <div className="c-l-i-date">
                                                        <p>DAY{index+1}</p><p>{simple}</p>
                                                    </div>
                                                    <div className="c-l-i-content">
                                                        <div className="c-l-i-content-inner">
                                                            {dayItem.content[0].map((tour,key) =>
                                                                <div className="c-l-i-content-item" key={"d_"+key}>
                                                                    <p className="title">{tour.title}</p>
                                                                    <div className="sub">
                                                                        <p className="c-l-i-d"><i className="fas fa-calendar-week"></i>{tour.days}天</p>
                                                                        <p className="c-l-i-p">${tour.price}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {dayItem.content[1].map((tour,key) =>
                                                                <div className="c-l-i-content-item repeat" key={"d2_"+key}>
                                                                    <p className="title">{tour.title}</p>
                                                                    <div className="sub">
                                                                        <p className="c-l-i-d"><i className="fas fa-calendar-week"></i>{tour.days}天</p>
                                                                        <p className="c-l-i-p">{tour.price}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {this.pickUP!==true?<p className="first-child-note" style={{"display":"none"}}><i className="fas fa-exclamation-circle"></i>請準時於行程約定地點集合(逾時不候及無法退費，敬請諒解）</p>:""}
                                                        {this.dropOFF!==true?<p className="last-child-note" style={{"display":"none"}}><i className="fas fa-exclamation-circle"></i>購買旅遊產品前請确認各行程時間及地點</p>:""}
                                                    </div>
                                                    <div id="customized-list-line"></div>
                                                </div>
                                            </div>
                                        )}
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isPickUPArrange===false||isdropOFFArrange===false?
                <div id="air-wrong">
                    <p><i className="fas fa-exclamation-circle"></i>你尚未安排接送機行程喔！</p>
                </div>:""
                }
                
            </div>
            
        );
    }
}




export default CustomizedList;