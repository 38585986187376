import React, { Component } from 'react';
import './StepDisplay.less';

class Step extends Component {    
    render() {
        return (   
            <div id="step-frame">
                <div id="step1" className={this.props.pageCount === 0? 'step now-step' : (
                    this.props.pageCount > 0? 'step step-done' : 'step'
                )} >
                    <div className="step-t">
                        <div className="step-line-none"></div>
                        <div className="step-number">1</div>
                        <div className="step-line"></div>
                    </div>
                    <div className="step-tip">選擇基本資料</div>

                </div>  
                <div id="step2" className={this.props.pageCount === 1? 'step now-step' : (
                    this.props.pageCount > 1? 'step step-done' : 'step'
                )}>
                    <div className="step-t">
                        <div className="step-line"></div>
                        <div className="step-number">2</div>
                        <div className="step-line"></div>
                    </div>
                    <div className="step-tip">挑選您有興趣的行程</div>

                </div> 
                <div id="step3" className={this.props.pageCount === 2? 'step now-step' : (
                    this.props.pageCount > 2? 'step step-done' : 'step'
                )}>
                    <div className="step-t">
                        <div className="step-line"></div>
                        <div className="step-number">3</div>
                        <div className="step-line"></div>
                    </div>
                    <div className="step-tip">安排您的旅遊行程</div>

                </div> 
                <div id="step4" className={this.props.pageCount === 3? 'step now-step' : (
                    this.props.pageCount > 3? 'step step-done' : 'step'
                )}>
                    <div className="step-t">
                        <div className="step-line"></div>
                        <div className="step-number">4</div>
                        <div className="step-line"></div>
                    </div>
                    <div className="step-tip">確認行程及填寫訂購表單</div>

                </div> 
                <div id="step5" className={this.props.pageCount === 4? 'step now-step' : (
                    this.props.pageCount > 4? 'step step-done' : 'step'
                )}>
                    <div className="step-t">
                        <div className="step-line"></div>
                        <div className="step-number"><i className="fas fa-check"></i></div>
                        <div className="step-line-none"></div>
                    </div>
                    <div className="step-tip">訂購完成</div>

                </div> 
            </div>
        );
    }
}

export default Step;