import React, { Component } from 'react';
import './IndexContent.less';
import axios from 'axios';
import TourDisplay from './TourDisplay/TourDisplay';

class IndexContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            tourMenus : []
        }
    }
    
    
    componentWillMount() {
        //得到menu
        let tourMenus = []
        axios.get('https://haoztravel.leyo.asia/C/tour/webservice/b2CTourTreeNodeTitleService-getNodeDescendant',
        { 
        })
        .then(res => {
            
            res.data.nodeChildren.forEach(item => {
                let tourMenusItem = {
                    nodeTitle : "",
                    subTitles : []
                }
                tourMenusItem.nodeTitle = item.nodeTitle
                tourMenusItem.nodeId = item.nodeId
                tourMenus.push(tourMenusItem)
                axios.get('https://haoztravel.leyo.asia/C/tour/webservice/b2CTourTreeNodeTitleService-getNodeDescendant?b2cTourTreeNodeTitleId='+item.nodeId,
                { 
                    params:{
                    }
                })
                .then(res => {
                    res.data.nodeChildren.forEach(item => {
                        let subTitlesItem = {
                            nodeTitle: "",
                            nodeId: ""
                        }
                        subTitlesItem.nodeTitle = item.nodeTitle
                        subTitlesItem.nodeId = item.nodeId
                        tourMenusItem.subTitles.push(subTitlesItem)

                        // axios.get('https://haoztravel.leyo.asia/C/tour/webservice/b2CTourTreeNodeTitleService-getNodeDescendant?b2cTourTreeNodeTitleId='+item.nodeId,
                        // { 
                        // })
                        // .then(res => {
                        //     console.log(res.data)
                        // })
                        // .catch(function (error) {
                
                        //     console.log(error);
                        // });
                        //     })
                        // })
                        // .catch(function (error) {

                        //     console.log(error);
                        // });
                    })
                })
            })
            console.log(tourMenus)
            this.setState({tourMenus:tourMenus})
        })
        .catch(function (error) {

            console.log(error);
        });
        console.log("1")

        //得到首頁行程
        console.log(tourMenus,"2")
        

    }

    

    render() {
        return (
            <div><TourDisplay />
            </div>
        );
    }
}

export default IndexContent;