import React, { Component } from 'react';
import PickedTourList from './PickedTourList/PickedTourList';
import CustomizedList from './CustomizedList/CustomizedList';
import GuestInfo from '../GuestInfo/GuestInfo';
class Step3Content extends Component {
    constructor(props){
        super(props);
        const{ deleteTour,selectTourDate, setCustomizedList, initialStepObject,setCart,calPackagePrice,calSumPeople,calPackageMinPrice,getValidPeopleGroup }=this.props;
        
        this.deleteTour = deleteTour;
        this.calPackagePrice = calPackagePrice
        this.calPackageMinPrice = calPackageMinPrice;
        this.calSumPeople = calSumPeople
        this.setCart = setCart;
        this.selectTourDate = selectTourDate;
        this.setCustomizedList = setCustomizedList;
        this.initialStepObject = initialStepObject;
        this.getValidPeopleGroup = getValidPeopleGroup;

    }
    componentWillMount() {
        this.calSumPeople();
        this.calPackagePrice();
        this.setCart();
        this.setCustomizedList();
    }
    
    render() {
        return (
            <div>
                <GuestInfo 
                pickedDate={this.props.pickedDate}/>
                <CustomizedList />
                <PickedTourList 
                deleteTour={this.deleteTour} 
                selectTourDate={this.selectTourDate}/>
            </div>
        );
    }
}

export default Step3Content;