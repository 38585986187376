import React, { Component } from 'react';
import 'flatpickr/dist/themes/airbnb.css'
 
import Flatpickr from 'react-flatpickr'
import LocalStorage from '../../Helpers/LocalStorage';
import './DatePick.less';

class DatePick extends Component {
    constructor(props) {
        super(props);
        const { getPickedDate,getAirOption } = this.props;
        this.getPickedDate = getPickedDate;
        this.getAirOption = getAirOption;
        this.state = {
            pickedDate: [this.props.pickedDate[0], this.props.pickedDate[1]]
        };

        this.initialDatePicker = this.initialDatePicker.bind(this);
    }
    componentWillMount() {
        this.initialDatePicker();

    }
    componentDidMount() {
        let flatpickr = document.getElementsByClassName('hasTime');

        let map = Array.prototype.map;
        map.call(flatpickr, obj=> obj.classList.remove("hasTime"));
        
    }

    //componentDidMount(){
      //document.getElementById("pickedDateStart").value=this.pickedDateStart;
      //document.getElementById("pickedDateEnd").value=this.pickedDateEnd;
    //}

    initialDatePicker(){
      let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if (haoztravelLS == null) {
            haoztravelLS = {};
        }

        this.pickedDateStart = haoztravelLS.pickedDateStart;
        this.pickedDateEnd = haoztravelLS.pickedDateEnd;
        LocalStorage.getLocalStorage("haoztravel")
        
    }
    
    
    

    render() {
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if (haoztravelLS == null) {
            haoztravelLS = {};
        }
        this.pickedDate = haoztravelLS.pickedDate;
        this.maxDate = haoztravelLS.maxDate;
        this.testDateEnd = haoztravelLS.testDateEnd
        this.pickUP = haoztravelLS.pickUP
        this.dropOFF = haoztravelLS.dropOFF
        return (
            <div className="block-t">

            <div className="basic-info-title">➤ 選擇您的旅遊日期</div>
            <div className="basic-info-content">
                <div id="content-inner">
                    <div id="date-block">
                        <Flatpickr data-enable-time
                        className="default"
                        value={this.pickedDate}
                        placeholder="請選擇旅遊日期.."
                        onChange={(e)=>{this.getPickedDate(e)}} 
                        options={{
                            mode: "range",
                            enableTime: false,
                            altFormat: "Y-m-d",
                            minDate: this.props.minDate,
                            maxDate: this.props.maxDate,
                            dateFormat: "Y-m-d"
                        }}/>
                        <div className="date-icon"><i className={"fas fa-calendar-week"}></i></div>
                    </div>
                    <div className="check-block">
                        <div className="item-box">
                            <label className="container">接機服務
                            <input 
                                type="checkbox"
                                name="pickUP"
                                defaultValue
                                checked={this.props.pickUP}
                                onChange={this.getAirOption}
                                />
                            <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="item-box">
                            <label className="container">送機服務
                            <input 
                                type="checkbox"
                                name="dropOFF"
                                defaultValue
                                checked={this.props.dropOFF}
                                onChange={this.getAirOption}
                                />
                            <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
              
            
            
        </div>
        );
    }
}

export default DatePick;