import React, { Component } from 'react';
import 'flatpickr/dist/themes/airbnb.css'
import Flatpickr from 'react-flatpickr';
import '.././Form.less';
class OffAirInfoForm extends Component {
    constructor(props){
        super(props)
        const { selectSeatCount } =this.props
        this.selectSeatCount = selectSeatCount
    }
    render() {
        const { service, zipCode, showForm,time } = this.props;        
        return (
            <div className={"bill-block "+(service=="pickOff"&&showForm?"":"hide")}>
                <div className="bill-inner-title">送機服務</div>
                <div className="bill-inner">

                    <div className="form-row">                        
                        <div className="row-item">
                            <nobr>航空公司代碼/班機號碼</nobr>
                            <input name="pickOffAirCodeAndFlightNumber" onKeyUp={this.props.handleFormChange.bind(this)}/>
                        </div>
                        <div className="row-item">
                            <nobr>班機起飛日期及時間</nobr>
                            <Flatpickr data-enable-time
                            className="default"
                            name="pickOffAirArriveTime"
                            onChange={(selectedDates, dateStr, instance)=>{
                                this.props.changeArrivedTime("pickOffAirArriveTime",dateStr)
                            }}
                            options={{
                                //altFormat: "Y-m-d",
                                //dateFormat: "Y-m-d"
                            }}/> 
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="row-item">
                            <nobr className="titleRequired">總人數(人數包含小孩及大人)</nobr>
                            <input type="text" name="pickOffPeopleCount" className="required numberRequired" onKeyUp={this.props.handleFormChange.bind(this)}/>                            
                        </div>
                        <div className="row-item">
                            <nobr>嬰兒椅 (0~2y)</nobr>
                            <select name="pickOffBabySeatCount" onChange={this.props.handleFormChange.bind(this)}>
                            　<option value="0">0</option>
                            　<option value="1">1</option>
                            　<option value="2">2</option>
                            　<option value="3">3</option>
                            </select>
                        </div>
                        <div className="row-item">
                            <nobr>兒童椅 (2~6y)</nobr>
                            <select name="pickOffChildSeatCount" onChange={this.props.handleFormChange.bind(this)}>
                            　<option value="0">0</option>
                            　<option value="1">1</option>
                            　<option value="2">2</option>
                            　<option value="3">3</option>
                            </select>
                        </div>                        
                    </div>
                    <div className="form-row">                                                
                        <div className="row-item">
                            <nobr className="titleRequired">接送時間</nobr>
                            <Flatpickr data-enable-time
                            className="default required"
                            name="pickOffTime"
                            onChange={(selectedDates, dateStr, instance)=>{
                                this.props.changeArrivedTime("pickOffTime",dateStr)
                            }}
                            options={{
                                //altFormat: "Y-m-d",
                                //dateFormat: "Y-m-d"
                            }}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="row-item">
                            <nobr className="titleRequired">接送地址</nobr>
                            <input name="pickOffAddress" className="required" onKeyUp={this.props.handleFormChange.bind(this)}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="row-item">
                            <nobr className="titleRequired">城市</nobr>
                            <input name="pickOffCity" className="required" onKeyUp={this.props.handleFormChange.bind(this)}/>
                        </div>
                        <div className="row-item">
                            <nobr>ZIP</nobr>
                            <input value={zipCode} disabled style={{"background":"lightgray"}}/>
                        </div>
                    </div>                    
                    <div className="form-row">
                        <div className="row-item">
                            <nobr>備註</nobr>
                            <textarea name="pickOffRemark" onKeyUp={this.props.handleFormChange.bind(this)}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OffAirInfoForm;