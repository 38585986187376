import React, { Component } from 'react';
import PickOptions from './PickOptions/PickOptions';
import BasicInfoForm from './BasicInfoForm/BasicInfoForm';
import UpAirInfoForm from './UpAirInfoForm/UpAirInfoForm';
import OffAirInfoForm from './OffAirInfoForm/OffAirInfoForm';
import TotalPrice from './TotalPrice/TotalPrice';
import zipCodeInfo from '../zipcode.json';
class Step4Content extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "service": "",
            "showNextBtn": false,
            "selectedAirport": "",
            "zipCode": "",
            "showForm": false,
            "pickUpAirCodeAndFlightNumber": "",
            "pickUpAirArriveTime": "",
            "pickUpPeopleCount": 0,
            "pickUpBabySeatCount": 0,
            "pickUpChildSeatCount": 0,
            "pickUpCity": "",
            "pickUpAddress": "",
            "pickUpRemark": "",
            "pickOffAirCodeAndFlightNumber": "",
            "pickOffAirArriveTime": "",
            "pickOffPeopleCount": 0,
            "pickOffBabySeatCount": 0,
            "pickOffChildSeatCount": 0,
            "pickOffTime": "",
            "pickOffCity": "",
            "pickOffAddress": "",
            "pickOffRemark": "",
            "basicEnFirstName": "",
            "basicEnLastName": "",
            "basicEmail": "",
            "basicSocialType": "",
            "basicSocialNumber": "",
            "basicNation": "",
            "basicPhoneCode": "",
            "basicPhoneNumber": "",
            "firstPrice":0
        }

        this.selectedService = this.selectedService.bind(this)
        this.checkShowNextBtn = this.checkShowNextBtn.bind(this)
        this.handleFormChange = this.handleFormChange.bind(this)
        this.showForm = this.showForm.bind(this)
        this.changeArrivedTime = this.changeArrivedTime.bind(this)

    }

    filterZipCode = (optionItemValue) => {
        let vm = this
        let currentZipCode = vm.state.zipCode
        let filterZipCode = zipCodeInfo.filter(function (info) {
            return currentZipCode === info.Zipcode;
        });
        let firstPrice;

        let filterAmount = filterZipCode.map(zipCodeObj => {
            for (let [key, value] of Object.entries(zipCodeObj)) {
                    if (key === optionItemValue && parseInt(value) !== undefined && parseInt(value) > 0) {
                        firstPrice = value;
                       return zipCodeObj;
                    }
            }
        });
        

        if(filterAmount[0]){
            this.setState({
                "firstPrice": firstPrice
            })
            return true
        }else{
            return false
        }
        
    }

    validateZip = (optionItemValue) => {
        let result = true;
        let requiredItems = document.getElementsByClassName("zipRequired")
        let zipItems = Array.from(requiredItems)
        let filterZipCode = this.filterZipCode(optionItemValue);
        zipItems.map((zipItem) => {
            if (isNaN(parseInt(zipItem.value)) || zipItem.value.length !== 5) {
                result = false;
                zipItem.classList.add("error")
                alert("請填寫完整五位數字ZIP碼")
            } else if (filterZipCode === false) {
                result = false;
                zipItem.classList.add("error")
                alert("此地區沒有提供服務")
            }
        })
        return result;
    }

    validateOption = () => {
        let result = true;
        let requiredItems = document.getElementsByClassName("optionRequired")
        let optionItems = Array.from(requiredItems)
        let validateZip;
        optionItems.map((optionItem) => optionItem.classList.remove("error"))
        optionItems.map((optionItem) => {
            if (optionItem.value === "") {
                result = false;
                optionItem.classList.add("error")
                alert("請選擇機場")
            }
            validateZip = this.validateZip(optionItem.value)

            if (validateZip === false) {
                result = false;
            }
        })
        return result;
    }


    selectedService(val) {
        let success = false;
        if (this.validateOption()) {
            success = true;
            this.setState({ "service": val, "showForm": true })
        } else {
            success = false;
            this.setState({ "service": val, "showForm": false })
        }
        if (!success)
            return false;
    }

    checkShowNextBtn() {
        let isTure = (this.state.service != "")
            && (this.state.selectedAirport != "")
            && (this.state.zipCode.length == 5);
        if (isTure)
            this.state.showNextBtn = true;
        else {
            this.state.showNextBtn = false;
            this.state.showForm = false;
        }
    }

    showForm() {
        this.setState({ "showForm": true })
    }

    changeArrivedTime(target, val) {
        this.setState({ [target]: val })
    }

    calTotalPrice() {
        let firstPrice = parseInt(this.state.firstPrice);
        if (this.state.service == "pickUp") {
            let pickUpPeopleCount = 0;
            if (!isNaN(parseInt(this.state.pickUpPeopleCount))) {
                pickUpPeopleCount = parseInt(this.state.pickUpPeopleCount)
            }

            if (pickUpPeopleCount > 0 ) {
                return firstPrice + ((pickUpPeopleCount - 1) * 5)
            }
        }
        else {
            let pickOffPeopleCount = 0;

            if (!isNaN(parseInt(this.state.pickOffPeopleCount))) {
                pickOffPeopleCount = parseInt(this.state.pickOffPeopleCount)
            }

            if (pickOffPeopleCount > 0 ) {
                return firstPrice + ((pickOffPeopleCount - 1) * 5)
            }
        }
        return 0;

    }

    handleFormChange = (e) => {
        const { checked, name, value, type } = e.target;
        if (name === "selectedAirport" && type === "select-one") {
            this.setState({
                [name]: valueToUpdate,
                "showForm": false
            })
        }
        const valueToUpdate = type === 'checkbox' ? checked : value;
        this.setState({
            [name]: valueToUpdate
        })

    }

    render() {
        this.checkShowNextBtn()
        const { updateOrderInfo } = this.props;
        return (
            <div style={{ "paddingBottom": "20px" }}>
                <PickOptions
                    service={this.state.service}
                    showNextBtn={this.state.showNextBtn}
                    selectedService={this.selectedService}
                    handleFormChange={this.handleFormChange}
                    showForm={this.showForm}
                />
                <UpAirInfoForm
                    service={this.state.service}
                    showForm={this.state.showForm}
                    zipCode={this.state.zipCode}
                    handleFormChange={this.handleFormChange}
                    changeArrivedTime={this.changeArrivedTime}
                />
                <OffAirInfoForm
                    service={this.state.service}
                    showForm={this.state.showForm}
                    zipCode={this.state.zipCode}
                    handleFormChange={this.handleFormChange}
                    changeArrivedTime={this.changeArrivedTime}
                />
                <BasicInfoForm
                    state={this.state}
                    showForm={this.state.showForm}
                    handleFormChange={this.handleFormChange}
                />
                <TotalPrice
                    showForm={this.state.showForm}
                    currentState={this.state}
                    firstPrice={this.state.firstPrice}
                    totalPrice={this.calTotalPrice()}
                    handleFormChange={this.handleFormChange}
                    updateOrderInfo={updateOrderInfo}
                />
            </div>
        );
    }
}

export default Step4Content;