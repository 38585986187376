import React, { Component } from 'react';
import './TourDetail.less';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import banner from './images/banner.jpeg';
import test from './images/test.jpg';

class TourDetail extends Component {
    constructor(args) {
        super(args)
		this.state = {
			dateArr : [],
			tourCode : "",
			pickTourDetail : {
				id : "",
				groupNumber : "",
				pickDate : "",
				totalSeats : "",
				avaliableSeats : "",
				deposit : "",
				priceData : []
			},
			isLoading : true,

			isClick : ["active","",""],
			isHide : ["","display-none","display-none"]

		}
		// this.turTourPlanNumber = this.props.number
		// this.domain = this.props.domain
		// this.display = [this.props.highlight,this.props.description,this.props.dailyTour,this.props.price,this.props.note]
        // this.giftDisplay = this.props.gift
        this.tourImagePaths = []
        this.tourHighlight = ""
		this.description = ""
		this.notice = ""
        this.price = []
        
		this.allPackage = []
		this.allHotels = []
        // this.getPickedDate = this.getPickedDate.bind(this);
        // this.getTourInfo = this.getTourInfo.bind(this);
		// this.getTourPrices = this.getTourPrices.bind(this);
        // this.getTourHotel = this.getTourHotel.bind(this);
        this.replaceTable = this.replaceTable.bind(this);
		this.escapeChars = this.escapeChars.bind(this);
		this.switchBlock = this.switchBlock.bind(this);
		
	}
    
    componentWillMount() {
        axios.get('https://haoztravel.leyo.asia/C/tour/webservice/tourPlanService-getFixedTurTourPlanInformationByTurTourPlanNumber',
			{
				params:{
					//turTourPlanNumber:"YVR19LHD4"
					turTourPlanNumber: this.props.match.params.id
				}
			})
            .then(res => {
                this.tourImagePaths = res.data.tourImagePaths
                this.tourSubject = res.data.tourSubject
                this.tourHighlight = this.escapeChars(res.data.tourHighlight)
                this.description = this.replaceTable(res.data.tourDescription)
                //this.description = res.data.turTourPlanDescLocaleGroup.zh_TW.description
                this.notice = res.data.tourNotice
                //this.notice = res.data.turTourPlanLocaleGroup.zh_TW.notice
                if(res.data.tourPriceIncludes){
                    this.price[0] = res.data.tourPriceIncludes
                }
                if(res.data.tourPriceExcludes){
                    this.price[1] = res.data.tourPriceExcludes
                }
				//this.price = [res.data.turTourPlanPriceDescGroup.zh_TW.priceInclude,res.data.turTourPlanPriceDescGroup.zh_TW.priceDoesNotInclude]
				this.setState({"isLoading":false})
                console.log(this.tourImagePaths)
            })
			.catch(function (error) {
				console.log(error);
            });
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    replaceTable(str) {
		str = str.replace(/<table/g, '<div class="table"><table');
		str = str.replace(/<\/table>/g, '</table></div>');
		return str;
    }
	escapeChars(str) {
		// str = str.replace(/&/g, '&amp;');
		// str = str.replace(/</g, '&lt;');
		// str = str.replace(/>/g, '&gt;');
		// str = str.replace(/'/g, '&acute;');
		// str = str.replace(/"/g, '&quot;');
		// str = str.replace(/\|/g, '&brvbar;');
		str = str.replace(/\n/g, '</br>');
		return str;
	}
	switchBlock(index) {
		let isClick = ["","","","",""]
		let isHide = ["display-none","display-none","display-none","display-none","display-none"]
		isClick[index] = "active"
		isHide[index] = ""
		this.setState({
			isClick : isClick,
			isHide : isHide
		})
	}

    render() {
        let isClick = this.state.isClick
		let isHide = this.state.isHide
        console.log(this.props.match.params.id)
        //console.log(this.tourHighlight,this.description,this.notice,this.price)
        return (
            <div>
                <Header />
                <div className="tour-detail-banner">
                    <Carousel showThumbs={false}  showStatus={false} autoPlay interval={3000} infiniteLoop className="presentation-mode">
                        {this.tourImagePaths.map(item => 
                            <div className="carousel-image" style={{"background":"url('"+item+"')"}}>
                                <img src="" />
                            </div>
                        )}
                    </Carousel>
                </div>
                <div className="tour-detail-top"></div>
                <div className="tour-detail-bottom">
                    <div className="title">{this.tourSubject}</div>
                    <div className="highlight" dangerouslySetInnerHTML={{ __html: this.tourHighlight}} />
                    <div className="main-content">
                        <div className="menu">
                            {this.description?<div className={isClick[0]+" menu-item"} onClick={this.switchBlock.bind(this,0)}>行程描述</div>:""}
                            {this.price.length!==0?<div className={isClick[1]+" menu-item"} onClick={this.switchBlock.bind(this,1)}>費用說明</div>:""}
                            {this.notice?<div className={isClick[2]+" menu-item"} onClick={this.switchBlock.bind(this,2)}>注意事項</div>:""}
                        </div>

                        
                        <div className={isHide[0]+" description-block"} dangerouslySetInnerHTML={{ __html: this.description}} />
                        
                
                        <div className={isHide[1]+" price-block"}>
                            {/* <div className="include-text" dangerouslySetInnerHTML={{ __html: this.price}} /> */}
                            {this.price[0]?<div className="price-include">
                                <i class="fas fa-check-circle"></i>費用包含
                                <div className="text" dangerouslySetInnerHTML={{ __html: this.price[0]}} />
                            </div>:""}
                            {this.price[1]?<div className="price-not-include">
                                <i class="fas fa-times-circle"></i>費用不包含
                                <div className="text" dangerouslySetInnerHTML={{ __html: this.price[1]}} />
                            </div>:""}
                        </div>
                    
                        <div className={isHide[2]+" note-block"} dangerouslySetInnerHTML={{ __html: this.notice}} />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default TourDetail;