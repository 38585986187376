import React, { Component } from 'react';
import LocalStorage from '../../Helpers/LocalStorage';
import './UndecidedPickedTourList.less'
class UndecidedPickedTourList extends Component {
    constructor(props){
        super(props);
        const{ deleteTour }=this.props;
        this.deleteTour = deleteTour
    }
    componentWillMount() {
        
        const $ = window.$
        $(function() { 
            $(window).scroll(function() {
                var scrollTop = $(document).scrollTop(); 
                if (scrollTop >= 300) {
                    $(".step2cart").css({"top":"0","position":"fixed","z-index": "99","left":"0","width": "100%"}); 
                }else{
                    $(".step2cart").css({"position":"static","width": "100%"}); 
                }
            }); 
        });
    }
    render() {
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        if (haoztravelLS == null) {
            haoztravelLS = {};
        }
        this.clickedTourList = haoztravelLS.clickedTourList;
        this.tourNums = (this.clickedTourList == null)? 0 : this.clickedTourList.length;
        return (
            <div id="accordion-outer">
            <div className="panel-group step2cart" id="accordion">
                <div className="panel panel-default">
                    <div className="panel-heading" data-toggle="collapse" data-parent="#accordion" 
                            href="#collapse">
                            <div id="accordion-title-text">
                                <div id="title-name">購物車</div>  
                                <div id="title-tip">
                                    <div id="tip-text">已選擇 {this.tourNums} 個行程</div>
                                </div>
                            </div>
                            <div id="accordion-title-sign">
                                <i className="fa fa-angle-down menu__icon--open"></i>
                                <i className="fa fa-angle-up menu__icon--close"></i>
                            </div>
                    </div>
                    <div id="collapse" className="panel-collapse collapse" aria-expanded="false">
                        <div id="undecided-picked-tour-list">
                            { this.clickedTourList!==undefined?this.clickedTourList.map((tourListItem,key) =>{
                                return (
                                    <div className="u-p-t-l-row" key={key}>
                                        <div className="u-p-t-l-row-info">
                                            <div className="u-p-t-l-title">{tourListItem.tourSubject}</div>
                                            <div className="u-p-t-l-row-subinfo">
                                                <div className="u-p-t-l-days"><i className="fas fa-calendar-week"></i>{tourListItem.tourDay}天</div>
                                                <div className="u-p-t-l-price">${tourListItem.minPrice} up</div>
                                            </div>
                                        </div>
                                        <div className="u-p-t-l-delete" onClick={this.deleteTour.bind(this,tourListItem.packageId,tourListItem.tourDay)}><i className="fas fa-minus"></i></div>
                                    </div>
                                )
                            }):""}
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>



            
        );
    }
}

export default UndecidedPickedTourList;