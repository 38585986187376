const LocalStorage = {
	saveLocalStorage: (key, data)=>{
		localStorage.setItem(key, JSON.stringify(data));
	},
	getLocalStorage: (key)=>{
		let result = null;
		try{
			result = JSON.parse(localStorage.getItem(key));
		}
		catch(e){

		}
		return result;
	},
	removeLocalStorage: (key)=>{
		return localStorage.removeItem(key);
	}
}

export default LocalStorage;