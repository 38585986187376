import React, { Component } from 'react';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import banner from './images/banner.jpeg'
import './Banner.less'

class Banner extends Component {
    constructor(args) {
    super(args)
    this.state = {
        bannerImages : []
    }
  
}
  
  componentWillMount() {
      axios.get('https://haoztravel.leyo.asia/C/tour/webservice/portalBigBannerService-mainSlider',
			{
			})
      .then(res => {
        this.setState({
            bannerImages : res.data
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
      //console.log(this.tourHighlight,this.description,this.notice,this.price)
      return (
          <div>
              <Carousel showThumbs={false}  showStatus={false} autoPlay interval={3000} infiniteLoop className="presentation-mode">
                  {this.state.bannerImages.map(item => 
                      <div className="carousel-image" style={{"background":"url('"+item.imageURL+"')"}}>
                          <img src="" />
                      </div>
                  )}
              </Carousel>
          </div>
      );
  }
}

export default Banner;