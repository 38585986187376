import React, { Component } from 'react';
import './Step5Content.less'
class Step5Content extends Component {
    constructor(props) {
        super(props)
    }
    
    render() {
        return (
            <div id="final-info-content">
	            <p className="color-red">恭喜訂購完成!</p>
	            <p>訂單編號：{this.props.orderNumber}</p>
                <p>訂單確認已寄至您的信箱，如有問題請洽客服人員</p>
	            <button type="button" className="row-btn" onClick={()=>this.props.backToHome()}>回首頁</button>
	        </div>
        );
    }
}

export default Step5Content;