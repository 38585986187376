import React, { Component } from 'react';
import LocalStorage from '../../Helpers/LocalStorage';
import './TourList.less'

class TourList extends Component {
    constructor(props){
        super(props);
        const{ changeTourClickState }=this.props;
        this.changeTourClickState = changeTourClickState;
        
    }
    render() {
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        this.pickedDateStart = haoztravelLS.pickedDateStart;
        this.tourListData = haoztravelLS.tourListData;
        return (
            <div>
                <div id="tags-pick">
                    <div className="tags-pick-tag">櫻花</div>
                    <div className="tags-pick-tag">春節</div>
                    <div className="tags-pick-tag">熱門</div>
                    <div className="tags-pick-tag">ＩＧ打卡</div>
                </div>
                <div id="tour-list">
                    {this.tourListData.map((tourListItem,key) => 
                        <div key={key} className="tour-item" style={tourListItem.isClick===true?{"background":"#f0f5f8"}:{"background":"white"}} onClick={this.changeTourClickState.bind(this,tourListItem.packageId)}>
                            <div className="tour-info-l">
                                <div className="tour-days"><p>{tourListItem.tourDay}</p><p>Day</p></div>
                                <div className="tour-photo" style={{"background":"url('"+tourListItem.photo+"')"}}>
                                    <div className="tour-price">${tourListItem.minPrice} up</div>
                                </div>
                            </div>
                            <div className="tour-info-r">
                                <p className="title">{tourListItem.tourSubject}</p>
                                <p className="intro">{tourListItem.description}</p>
                                <div className="small-info">
                                    <div className="days"><p>{tourListItem.tourDay}</p><p>天</p></div>
                                    <div className="price">${tourListItem.minPrice} up</div>
                                </div>
                                <div className="tour-item-tags">
                                    {/*<div className="tour-tag"></div>*/}
                                </div>
                            </div>
                        </div>)
                    }
                    
                </div>
            </div>
        );
    }
}

export default TourList;