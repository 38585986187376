import React, { Component } from 'react';
import '.././Form.less'

class BasicInfoForm extends Component {
    constructor(props){
        super(props);
    }

    generateAirports = ()=>{
        const airports = [
            {   
                "airportName":"舊金山",
                "airportEnName":"SAN FRANCISCO",
                "airportCode":"SFO"

            },
            {   
                "airportName":"聖荷西",
                "airportEnName":"SAN JOSE",
                "airportCode":"SJC",
            },
            {   
                "airportName":"奧克蘭",
                "airportEnName":"OAKLAND",
                "airportCode":"OAK",
            },
            {   
                "airportName":"洛杉磯",
                "airportEnName":"LOS ANGELES",
                "airportCode":"LAX",
            },

            {   
                "airportName":"安大略",
                "airportEnName":"ONTARIO",
                "airportCode":"ONT",
            }
        ]
        let airportOptions = airports.map((c,k)=>{
            return <option name="" value={c.airportCode} key={k}>{c.airportName+"("+c.airportCode+")"}</option>
        })
        let airportContent = (
            <select id="selectedAirport" name="selectedAirport" className="optionRequired" onChange={this.props.handleFormChange.bind(this)}>
              <option value="">請選擇機場</option>
            　{airportOptions}
            </select>)
        return airportContent
    }

    

  render() {
    const{service, showNextBtn} = this.props    
    return (
        <div className="bill-block">            
            <div className="bill-inner">
                
                <div className="form-row">                                        
                    <div className="row-item">
                        <nobr className="titleRequired">機場</nobr> 
                        {this.generateAirports()}
                    </div>                    
                    <div className="row-item">
                        <nobr className="titleRequired">接送地址ZIP</nobr> 
                        <input type="text" name="zipCode" placeholder="請填入五碼數字" className="zipRequired"  onKeyUp={this.props.handleFormChange.bind(this)}/>
                    </div>
                </div>
                <div className="bill-inner-title">服務選擇
                    { service=="pickUp"?<span>: 接機服務</span>:""}
                    { service=="pickOff"?<span>: 送機服務</span>:""}
                </div>
                <div className="form-row btn-row">                    
                    <button type="button" className={"row-btn "+(service=="pickUp"?"selected":"")} onClick={()=>this.props.selectedService("pickUp")}>接機服務</button>
                    <button type="button" className={"row-btn "+(service=="pickOff"?"selected":"")} onClick={()=>this.props.selectedService("pickOff")}>送機服務</button>
                </div>
                
                {/* { showNextBtn?
                    <div className="form-row">                    
                        <div className="row-btn center" style={{"padding": "10px 0"}} onClick={()=>this.props.showForm()} disabled>下一步</div>
                    </div>:""
                } */}
            </div>
        </div>
    );
  }
}

export default BasicInfoForm;