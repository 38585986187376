import React, { Component } from 'react';
import './PeopleNumPick.less';
import qs from "query-string";
import _ from 'lodash';

class PeopleNumPick extends Component {
    constructor(props) {
        super(props);
        this.initPeopleGroup = this.initPeopleGroup.bind(this);
    }

    componentWillMount() {
        this.initPeopleGroup();
    }

    initPeopleGroup() {
        let peopleNumberGroup = qs.parse(window.location.search);
        let maxAdultPeopleNumber = 10;
        let maxKidPeopleNumber = 5;
        let maxKidNBPeopleNumber = 5;
        let maxInfantPeopleNumber = 4;
        this.initPeopleGroup = {
            adults: [],
            kids: [],
            kidNBs: [],
            infants: []
        }

        for (var num = 0; num <= maxAdultPeopleNumber; num++) {            
            let optionSetting = {
                val: num,
                text: num,
                isSelected: false
            };

            if (num === 0)
                optionSetting.text = "請選擇";

            let adultOption = _.cloneDeep(optionSetting);
            if(peopleNumberGroup.adult !== null && parseInt(peopleNumberGroup.adult)===num)
                adultOption.isSelected = true;
            this.initPeopleGroup.adults.push(adultOption);

        }
        for (let num = 0; num <= maxKidPeopleNumber; num++) {            
            let optionSetting = {
                val: num,
                text: num,
                isSelected: false
            };

            if (num === 0)
                optionSetting.text = "請選擇";

            let kidOption = _.cloneDeep(optionSetting);
            if(peopleNumberGroup.kid !== null && parseInt(peopleNumberGroup.kid)===num)
                kidOption.isSelected = true;
            this.initPeopleGroup.kids.push(kidOption);
        }
        for (let num = 0; num <= maxKidNBPeopleNumber; num++) {            
            let optionSetting = {
                val: num,
                text: num,
                isSelected: false
            };

            if (num === 0)
                optionSetting.text = "請選擇";


            let kidNBOption = _.cloneDeep(optionSetting);
            if(peopleNumberGroup.kidNB !== null && parseInt(peopleNumberGroup.kidNB)===num)
                kidNBOption.isSelected = true;
            this.initPeopleGroup.kidNBs.push(kidNBOption);
        }
        for (let num = 0; num <= maxInfantPeopleNumber; num++) {            
            let optionSetting = {
                val: num,
                text: num,
                isSelected: false
            };

            if (num === 0)
                optionSetting.text = "請選擇";


            let infantOption = _.cloneDeep(optionSetting);
            if(peopleNumberGroup.infant !== null && parseInt(peopleNumberGroup.infant)===num)
                infantOption.isSelected = true;
            this.initPeopleGroup.infants.push(infantOption);
        }
    }

    render() {
        const { updatePeopleNumberGroup } = this.props;
        return (
            <div className="block-b">
            <div className="basic-info-title">➤ 選擇您的參加人數
            {/*<p className="notice">＊ 嬰兒 0-2 歲, 兒童不佔床 3-6 歲, 兒童佔床 7-11 歲, 12 歲以上同大人</p>*/}</div>
            <div className="basic-info-content">
                <div id="num-block">
                    <div className="row">
                        <div className="row-item">
                            <p className="title">大人<span>(12y 以上)</span></p>
                            <div className="row-line"></div>
                            <select name="select-num" id="adult" onChange={(e)=>{updatePeopleNumberGroup(e.target)}} className="select-num">

                                {
                                    this.initPeopleGroup.adults.map((adult,key)=>{
                                        if(adult.isSelected)
                                            return <option key={key} value={adult.val} selected>{adult.text}</option>
                                        else
                                            return <option key={key} value={adult.val}>{adult.text}</option>                                  
                                    })
                                }
                            </select>
                        </div>
                        <div className="row-item">
                            <p className="title">嬰兒<span>(0-2y)</span></p> 
                            <div className="row-line"></div>
                            <select name="select-num" id="infant" onChange={(e)=>{updatePeopleNumberGroup(e.target)}} className="select-num">
                                {
                                    this.initPeopleGroup.infants.map((infant,key)=>{
                                        if(infant.isSelected)
                                            return <option key={key} value={infant.val} selected>{infant.text}</option>
                                        else
                                            return <option key={key} value={infant.val}>{infant.text}</option>                                  
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row-item">
                            <p className="title">兒童佔床<span>(7-11y)</span></p> 
                            <div className="row-line"></div>
                            <select name="select-num" id="kid" onChange={(e)=>{updatePeopleNumberGroup(e.target)}} className="select-num">
                                {
                                    this.initPeopleGroup.kids.map((kid,key)=>{
                                        if(kid.isSelected)
                                            return <option key={key} value={kid.val} selected>{kid.text}</option>
                                        else
                                            return <option key={key} value={kid.val}>{kid.text}</option>                                  
                                    })
                                }
                            </select>
                        </div>
                        <div className="row-item">
                            <p className="title">兒童不佔床<span>(3-6y)</span></p> 
                            <div className="row-line"></div>
                            <select name="select-num" id="kidNB" onChange={(e)=>{updatePeopleNumberGroup(e.target)}} className="select-num">
                                {
                                    this.initPeopleGroup.kidNBs.map((kidNB,key)=>{
                                        if(kidNB.isSelected)
                                            return <option key={key} value={kidNB.val} selected>{kidNB.text}</option>
                                        else
                                            return <option key={key} value={kidNB.val}>{kidNB.text}</option>                                  
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    {/*<div className="small-notice">＊ 嬰兒 0-2 歲, 兒童不佔床 3-6 歲, 兒童佔床 7-11 歲, 12 歲以上同大人</div>*/}
                </div>
            </div>
        </div>
        );
    }
}

export default PeopleNumPick;