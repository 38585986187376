import React, { Component } from 'react';
import './TourDisplay.less';
import { Link } from 'react-router-dom'
import axios from 'axios';
import sample from './images/sample.jpeg'

class TourDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tourData : []
        }
    }
    componentWillMount() {
        axios.get('https://haoztravel.leyo.asia/C/tour/webservice/b2CTourTreeNodeTitleService-getNodeDescendant?b2cTourTreeNodeTitleId=&maxRecursion=4',
        { 
        })
        .then(res => {
            res.data.nodeChildren.forEach(item => {
                let tourData = this.state.tourData
                let itemNodeTitle = {
                    nodeTitle : item.nodeTitle,
                    tourArr : []
                }
                item.nodeChildren.forEach(item2 => {
                    if(item2.nodeChildren){
                        let tourData = this.state.tourData
                        let item2NodeTitle = {
                            nodeTitle : item2.nodeTitle,
                            tourArr : []
                        }
                        item2.nodeChildren.forEach(item3 => {
                            if(item3.nodeChildren){
                                item3.nodeChildren.forEach(item4 => {
                                        
                                })
                            }else {
                                let tourObj = {
                                    nodeTitle : "",
                                    turTourNumber: "",
                                    turSubject : "",
                                    turImagePath : "",
                                }
                                    tourObj.turSubject = item3.turSubject
                                    tourObj.turTourNumber = item3.turTourNumber
                                    tourObj.turImagePath = item3.turImagePath
                                    tourObj.turTourDay = item3.turTourDay
                                    tourObj.nodeTitle = item2.nodeTitle
                                if(tourObj.turSubject!==undefined){
                                    item2NodeTitle.tourArr.push(tourObj)
                                }
                                
                            }   
                        })
                        if(item2NodeTitle.tourArr.length!==0){
                            tourData.push(item2NodeTitle)
                            this.setState({
                                tourData : tourData
                            })
                        }
                    }else {
                        let tourData = this.state.tourData
                        let tourObj = {
                            nodeTitle : "",
                            turTourNumber: "",
                            turSubject : "",
                            turImagePath : "",
                        }
                            tourObj.turSubject = item2.turSubject
                            tourObj.turTourNumber = item2.turTourNumber
                            tourObj.turImagePath = item2.turImagePath
                            tourObj.turTourDay = item2.turTourDay
                            tourObj.nodeTitle = item.nodeTitle
                        if(tourObj.turSubject!==undefined){
                            itemNodeTitle.tourArr.push(tourObj)
                        }

                        
                    }
                        
                })
                if(itemNodeTitle.tourArr.length!==0){
                    tourData.push(itemNodeTitle)
                    this.setState({
                        tourData : tourData
                    })
                }
            })
        })
        .catch(function (error) {

            console.log(error);
        });
    }

    render() {
        console.log(this.state.tourData)
        return (
            <div>
            {/*  <div className="tourDisplay">
                 <div className="block">
                     <div className="menu-block">
                         <span><p>拼團出遊</p></span>
                         <div className="subMenu-block">
                             <div className="subMenu-item active">台北</div>
                             <div className="subMenu-item">新竹</div>
                             <div className="subMenu-item">台中</div>
                             <div className="subMenu-item">高雄</div>
                         </div>
                     </div>
                     <div className="tour-block">
                         <div className="tour-item">
                             <div className="tour-item-img" style={{"background":"url('"+sample+"')"}}></div>
                             <div className="tour-item-title">三天清境非GO不可三天清境非GO不可</div>
                             <div className="tour-item-day"><i class="fas fa-calendar-day">3<span>days</span></i></div>
                         </div>
                     </div>
                 </div>
             </div> */}
            {this.state.tourData.map(item => 
                <div className="tourDisplay">
                    <div className="block">
                        <div className="menu-block">
                            <span><p>{item.nodeTitle}</p></span>
                            {/* <div className="subMenu-block">
                                <div className="subMenu-item active">台北</div>
                                <div className="subMenu-item">新竹</div>
                                <div className="subMenu-item">台中</div>
                                <div className="subMenu-item">高雄</div>
                            </div> */}
                        </div>
                        <div className="tour-block">
                            {item.tourArr.map(tourItem => 
                                <Link to={`/tour-detail/${tourItem.turTourNumber}`}>
                                {console.log(tourItem)}
                                    <div className="tour-item">
                                        <div className="tour-item-img" style={{"background":"url('"+tourItem.turImagePath+"')"}}></div>
                                        <div className="tour-item-title">{tourItem.turSubject}</div>
                                        <div className="tour-item-day"><i class="fas fa-calendar-day">{tourItem.turTourDay}<span>days</span></i></div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            
            )}
            </div>
        );
    }
}

export default TourDisplay;