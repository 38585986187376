import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Main from './Main/Main';
import Step4Content from './Step4Content/Step4Content';
import HaozIndex from './Haoz/HaozIndex/HaozIndex';
import TourDetail from './Haoz/TourDetail/TourDetail';
import About from './Haoz/About/About';

const BasicRoute = () => {
    return(
    	<BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
	    	<Switch>
				<Route path="/" component={Main}/>
		    </Switch>
	    </BrowserRouter>
    )
};


export default BasicRoute;