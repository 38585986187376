import React, { Component } from 'react';
import './Footer.less';
import pay from'./images/pay.png';
import LOGO from '../LOGO.png';

class Footer extends Component {
    
    
    

    render() {
        return (
            <div className="footer">
                <div className="footer-content">
                    <div className="footer-logo" style={{"backgroundImage":"url('"+LOGO+"')"}}></div>
                    <div className="footer-text1">
                        <p>TEL: 408-727-0570</p>
                        <p>FAX: 408-727-9529</p>
                        <p>EMAIL: service@marisantravel.com</p>
                    </div>                    
                </div>
            </div>
        );
    }
}

export default Footer;