import React, { Component } from 'react';
import './SmallStepDisplay.less'
class SmallStepDisplay extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageCount: this.props.pageCount,
            page:null,
            pageContent: "某些東西錯誤了"
        };
    }
    componentDidMount() {
        if(this.props.pageCount===0){
            this.setState({page:1,pageContent:"選擇基本資料"})
        }else if(this.props.pageCount===1){
            this.setState({page:2,pageContent:"挑選您有興趣的行程"})

        }else if(this.props.pageCount===2){
            this.setState({page:3,pageContent:"安排您的旅遊行程"})
            
        }else if(this.props.pageCount===3){
            this.setState({page:4,pageContent:"確認行程及填寫訂購表單"})
            
        }else if(this.props.pageCount===4){
            this.setState({page:5,pageContent:"訂購完成"})
            
        }
    }
    render() {
        return (
            <div id="small-step-main">
                <div id="small-step-num">
                    <div className="small-step-line"></div>
                    <div id="circle">
                        <p id="step-text">{
                            this.props.pageCount===0?1:
                            this.props.pageCount===1?2:
                            this.props.pageCount===2?3:
                            this.props.pageCount===3?4:
                            this.props.pageCount===4?5:""}
                        </p>
                    </div>
                    <div className="small-step-line"></div>
                </div>
                <div id="small-step-text">
                    <div id="block-line">{
                            this.props.pageCount===0?"選擇基本資料":
                            this.props.pageCount===1?"挑選您有興趣的行程":
                            this.props.pageCount===2?"安排您的旅遊行程":
                            this.props.pageCount===3?"確認行程及填寫訂購表單":
                            this.props.pageCount===4?"訂購完成":""}
                    </div>
                </div>

            </div>
        );
    }
}

export default SmallStepDisplay;