import React, { Component } from 'react';
import './About.less';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

class About extends Component {
    
    
    

    render() {
        return (
            <div>
                <Header />
                <div className="About">
                    <div className="title">關於我們</div>
                    <p>
                        2018年春暖花開之際，豪邁創立於台灣台北。<br/><br/>

                        創業的機緣源自一次交情深厚的同業聚會，十幾年同事情誼的餐敘中，<br/>
                        話題一如既往，圍繞著對旅遊業現況和未來的探究分析。<br/><br/>

                        在彼此理念和目標相近之下，很快擦出了共同創業的火花，<br/>
                        帶著對本業的憧憬和衝勁成立「豪邁國際旅行社股份有限公司」。 <br/><br/>

                        並以「共享、共遊、共樂」為概念，創立Haoz Travel豪自遊品牌，<br/>
                        提供更快捷、實惠的旅遊平台。<br/><br/>

                        經營團隊由多位團體操作與帶團經驗豐富的OP、導遊、業務、科技新秀等同業伙伴所組成。 <br/><br/>
                        我們以誠信為宗旨，創新為基礎，珍惜所託為服務目標。
                    </p>
                    
                </div>
                <Footer />
            </div>
        );
    }
}

export default About;