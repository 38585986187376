import React, { Component } from 'react';
import LocalStorage from '../Helpers/LocalStorage';
import qs from "query-string";
import './GuestInfo.less'
class GuestInfo extends Component {
    constructor(props) {
        super(props);
        this.catchPeopleNum = this.catchPeopleNum.bind(this);
        this.state = {
            peopleNum : 0
        }
    }

    componentWillMount() {
        let haoztravelLS = LocalStorage.getLocalStorage("haoztravel")
        this.pickedDateStart = haoztravelLS.pickedDateStart;
        this.pickedDateEnd = haoztravelLS.pickedDateEnd;
        this.pickedDateInterval = haoztravelLS.pickedDateInterval;
        this.catchPeopleNum();
    }
    catchPeopleNum() {
        let peopleNumberGroup = qs.parse(window.location.search);
        let peopleNum = 0
        Object.keys(peopleNumberGroup).forEach(item => {
            peopleNum += parseInt(peopleNumberGroup[item])
        })
        this.setState({ peopleNum:peopleNum })
    }

    render() {
      let correctPickedDate = this.pickedDateStart.substr(0,10) +" ~ "+ this.pickedDateEnd.substr(0,10)
      return (
          <div id="guest-info">
              <p>{correctPickedDate}</p><p> <i className="fas fa-calendar-week"></i> {this.pickedDateInterval.length} 天 &nbsp; <i className="fas fa-user-friends"></i> {this.state.peopleNum}</p>
          </div>
      );
    }
}

export default GuestInfo;